import React, {useState} from 'react';
import './scss/App.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import TopBar from './components/TopBar';

function Item({item}) {
  const [done, setDone] = useState(false)
  return (
    <div className="item" style={{backgroundColor: item?.color || 'red', opacity: done ? 0.6 : 1}}
      onClick={()=>{
        setDone(!done);
      }}>
      <div className="itemTitle">
        <div className="text">
          {item?.title || 'Polski'}
        </div>
        <div className="date">
          05-10-2020
        </div>
      </div>
      <div className="descContainer">
        <div className="description"dangerouslySetInnerHTML={{__html: item?.content || 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. At, provident.'}}>
        </div>
        <div className="checkBox">
          <div className="checkBoxContainer">
            <div className="icon" style={{opacity: done ? 1 : 0}}>
              <FontAwesomeIcon icon={faCheck} size="0.3x" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
function App() {
  return (
    <div className="app">
      <div className="author">
        <a href="https://unsplash.com/photos/aA30WgMHxuA">Zdjęcie</a>
      </div>
      <TopBar />
      <div className="content">
        <div className="title">
          <div className="bck"></div>
          <div className="mainTitle">
            Hej,
          </div>
          <div className="subTitle">
            było coś z polaka?
          </div>
        </div>
        <div className="items">
          <Item item={{color: '#f04e43', title: "Polski", content: `Przeczytać ,,Lalkę" <br> Ćwiczenie 10 i 11, str 18`}}/>
          <Item item={{color: '#56a337', title: 'Matma', content: `zad. 1,2,3 str 19 <br> Dla chętnych: 4,5 str 20`}}/>
        </div>
        <a href='https://play.google.com/store/apps/details?id=com.dziuda.zklasa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="badge" alt='pobierz z Google Play' src='https://play.google.com/intl/pl_pl/badges/static/images/badges/pl_badge_web_generic.png'/></a> 
      </div>
    </div>
  );
}

export default App;
