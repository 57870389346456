import React, { Component } from 'react';
import './scss/TopBar.scss';

export default class TopBar extends Component {
  render() {
    return (
      <div className="topBar">
        <a href="https://dziuda.it">
          <div className="topBarSection">Hubert Dziuda</div>
        </a>
        <a href="https://zklasa.dziuda.it/regulamin.html">
          <div className="topBarSection">Regulamin</div>
        </a>
      </div>
    )
  }
}
